import {useLoaderData} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/server-runtime';

import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum} from '@/enums';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import customStyleSheet from '@/stylesheets/enterprise/blog/styles.css?url';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {useTranslations} from '@/hooks/useTranslations';
import {BlogSubNav} from '@/pages/shopify.com/($locale)/enterprise/blog/components/BlogSubNav/BlogSubNav';
import type {EnterpriseIndexSectionArticles} from '@/pages/shopify.com/($locale)/enterprise/blog/types';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';
import {SolutionsFragment} from '@/pages/shopify.com/($locale)/enterprise/blog/components/SolutionsFragment/SolutionsFragment';
import Hero from '@/components/pages/blog/Hero/Hero';
import LatestSection from '@/components/pages/blog/LatestSection/LatestSection';
import Conversion, {
  CtaStyles,
  EnterpriseHeadingStyles,
} from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';
import TwoPane from '@/components/pages/blog/BlogIndex/sections/TwoPane';
import ThreePane from '@/components/pages/blog/BlogIndex/sections/ThreePane';

import {enterpriseBlogLoader} from './server/blogIndexLoader.server';

export {enterpriseHandle as handle} from '@/pages/shopify.com/($locale)/enterprise/blog/handle';

export const loader = enterpriseBlogLoader;

export const EN_ENTERPRISE_BLOG_PATH = '/enterprise/blog/';

export {combinedLeadAction as action} from '@/pages/shopify.com/($locale)/enterprise/blog/server/action.server';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: customStyleSheet}];
};

export default function Blog() {
  const {
    featuredHeroArticle,
    heroArticles = [],
    latestArticles = [],
    mostReadArticles = [],

    category1Articles = [],
    category2Articles = [],
    category3Articles = [],
    miniCategory2Articles = [],
    featuredCategory3Articles = [],
    b2bSection = [],

    subNavTopics,
    settings,
  } = useLoaderData<EnterpriseIndexSectionArticles>() ?? {};
  const {t} = useTranslations();
  const blogType = BLOG_PAGE_TYPE.ENTERPRISE;

  const conversion = (
    <BlogConversion
      heading={t('sellAnywhere.headingHtml')}
      subhead={t('sellAnywhere.subheadHtml')}
      buttonHref={t('sellAnywhere.button.href')}
      buttonText={t('sellAnywhere.button.text')}
      disclaimer={t('sellAnywhere.disclaimerHtml')}
      mode="enterprise"
      сomponentName="cta-footer-banner"
    />
  );

  const pageTitle = useBlogPageTitle(t('htmlHead.pageTitle'));
  const heroConversionCta = featuredHeroArticle && (
    <SolutionsFragment isHero componentName="home-CTA-block-email" />
  );

  return (
    <PageLayout
      title={pageTitle}
      header={(props) => (
        <BlogSubNav
          {...props}
          subNavTopics={subNavTopics || []}
          enBlogPath={EN_ENTERPRISE_BLOG_PATH}
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      isPureTitle
    >
      <div className="blog relative pt-36 text-black hero-dark-green-noise pb-10">
        <div className="container flex justify-center items-center pb-6 pt-10 desktop:pb-8 desktop:pt-16 border-b border-white border-opacity-10">
          <h1 className="text-t5 desktop:text-t4 !font-normal text-section-dark-text text-center">
            {t('hero.title')}
          </h1>
        </div>

        <Hero
          blogType={blogType}
          featuredArticle={featuredHeroArticle}
          secondaryArticles={heroArticles}
          enBlogPath={EN_ENTERPRISE_BLOG_PATH}
          conversionCta={heroConversionCta}
          mode="dark"
        />
      </div>
      <div className="blog relative pt-0 text-black">
        <div className="section-dark-mobile-green-background tablet-xl:section-dark-green-background pb-10 text-white hover:[&_.header-link]:text-shade-30">
          {latestArticles.length > 0 && (
            <LatestSection
              blogType={blogType}
              sectionHead={t('latestArticles')}
              primaryArticles={latestArticles}
              enBlogPath={EN_ENTERPRISE_BLOG_PATH}
            />
          )}
        </div>
        <div className="-mt-10 bg-section-light-bg rounded-t-[30px] desktop:rounded-t-[48px] font-faktum [&_>h4]:!font-medium">
          {(category1Articles.length > 0 || category2Articles.length > 0) && (
            <ThreePane
              isEnableMarketingBlog
              topArticles={category1Articles}
              leftArticles={category2Articles}
              rightArticles={miniCategory2Articles}
              heading={settings.threePane.heading}
              subHeading={settings.threePane.subHeading || ''}
              leftSubHeading={settings.threePane?.leftSubHeading || ''}
              enBlogPath={EN_ENTERPRISE_BLOG_PATH}
              blogType={blogType}
              mode="light"
            />
          )}
        </div>
        <div className="subscribe-section subscribe-banner-bg tablet:py-8 desktop:py-16 relative">
          <Conversion
            {...t('conversion')}
            headingClassName={EnterpriseHeadingStyles}
            cta={{...t('conversionSubscription.conversionCta')}}
            mode="dark"
            ctaClassName={CtaStyles({blogType})}
            subscriptionId="remove-me"
            className="mx-margin py-8 tablet:py-16"
          />
        </div>
        {featuredCategory3Articles.length > 0 && (
          <div className="bg-section-light-bg rounded-b-[30px] desktop:rounded-b-[48px] relative z-10">
            <TwoPane
              blogType={blogType}
              leftArticles={featuredCategory3Articles}
              rightArticles={category3Articles}
              heading={settings.twoPane.heading}
              categoryUrl={settings.twoPane.url}
              enBlogPath={EN_ENTERPRISE_BLOG_PATH}
              mode="light"
            />
            {b2bSection.length > 0 && (
              <ArticleCarousel
                {...t('b2b')}
                blogType={blogType}
                primaryArticles={b2bSection}
                enBlogPath={EN_ENTERPRISE_BLOG_PATH}
                carouselName="b2b"
                className="!pt-0 pb-16 tablet-xl:pb-20 desktop:pb-20 b2b-container"
                headingTag="h3"
                blogCardMode="light"
              />
            )}
          </div>
        )}
        {mostReadArticles.length > 0 && (
          <div className="section-most-read-green-background pb-16 tablet-xl:pb-20 desktop:pb-20 -mt-12 pt-8 desktop:pt-24">
            <ArticleCarousel
              {...t('mostRead')}
              blogType={blogType}
              primaryArticles={mostReadArticles}
              enBlogPath={EN_ENTERPRISE_BLOG_PATH}
              carouselName="most-read"
              className="most-read-container"
              blogCardMode="light"
              headingTag="h4"
            />
          </div>
        )}
        {conversion}
      </div>
    </PageLayout>
  );
}
